@import '../../styles/customMediaQueries.css';

.favoritesList {
  width: 100%;
  
  @media (--viewportSmall) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  @media (--viewportMedium) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.favoritesListItem {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0 0 20px;
  cursor: pointer;
  
  @media (--viewportSmall) {
    min-height: 290px;
  }
  
  @media (--viewportMedium) {
    min-height: unset;
  }

  & .favoritesImageHolder {
    height: 240px;
    min-height: 240px;
    
    @media (--viewportMedium) {
      height: 150px;
      min-height: 150px;
    }
  }
}
 
.favoritesListImage {
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
  border-radius: 5px;
  height: 240px;
  object-fit: cover;
  
  @media (--viewportMedium) {
    max-width: 200px;
    max-height: 150px;
  }
}

.favoritesListTitle {
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  padding: 0;
  word-break: break-word;
}

.favoritesListCounter {
  margin: 0;
  padding: 0;
  color: var(--colorGrey500);
  font-size: 12px;
  font-weight: var(--fontWeightRegular);
  line-height: 18px;
}

/* is link */

.favoritesListGrid {
  width: 100%;
  
  @media (--viewportSmall) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  @media (--viewportMedium) {
    padding: 0 0 96px 0;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewportLarge) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 1440px) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.favoritesListLinkItem {
  position: relative;
  width: 100%;
  margin: 0 0 20px;
  padding: 0;
  cursor: pointer;
}
 
.favoritesListLink {
  display: flex;
  flex-direction: column;
  height: 100%;
  color: var(--colorGrey700);

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.favoritesListLinkImage {
  display: block;
  width: 100%;
  height: 100%;
  max-height: 240px;
  min-height: 240px;
  margin-bottom: 10px;
  object-fit: cover;
  border-radius: 5px;
  
  @media (--viewportMedium) {
    max-height: 306px;
    height: 306px;
  }
}

.favoritesListLinkTitle {
  margin: 0;
  padding: 0;
  word-break: break-word;
}

.favoritesListLinkCounter {
  margin: 0;
  padding: 0;
  color: var(--colorGrey500);
  font-size: 12px;
  font-weight: var(--fontWeightRegular);
  line-height: 24px;
}

.favoritesDelete {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  border: 1px solid var(--colorGrey300);
  background: rgba(255, 255, 255, 0.70);
}

.favoritesImageHolder {
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--colorGrey300);
  border-radius: 5px;
  height: 240px;
  
  @media (--viewportMedium) {
    height: 306px;
  }
}

.favoritesListLogo {
  width: 150px;
  height: auto;
  max-height: 100px;
}
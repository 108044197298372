.root {
}

.selectError {
  border: var(--borderErrorField);
}

.selectSuccess {
  border: var(--borderSuccessField);
}


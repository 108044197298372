@import '../../styles/customMediaQueries.css';

.root {
  max-width: 756px;

  & :global(.rdw-editor-toolbar) {
    border: none;
    border: 1px solid var(--successColor);
    border-bottom: none;
    padding: 11px 12px;
    margin-bottom: 0;
    
    @media (--viewportMedium) {
      padding: 11px 16px;
    }
  }


  & :global(.rdw-fontsize-dropdown) {
    width: 48px;
  }

  & :global(.rdw-option-wrapper),
  & :global(.rdw-dropdown-wrapper) {
    border-color: var(--colorGrey300);
    margin: 0 2px;
    border-radius: 2px;

    @media (--viewportSmall) {
      margin: 0 4px;
    }

    &:hover,
    &:focus {
      border-color: var(--marketplaceColor);
      box-shadow: none;
    }
  }

  & :global(.rdw-option-active) {
    border-color: var(--marketplaceColor);
    box-shadow: 0 0 2px var(--marketplaceColor);
  }

  & :global(.rdw-embedded-modal) {
    height: auto;
  }

  & :global(.rdw-embedded-modal-size) {

    & > span {
      position: relative;

      & :global(.rdw-image-mandatory-sign) {
        position: absolute;
        right: 4px;
        top: -8px;
      }
    }
  }

  & :global(.rdw-image-modal-size) {
    position: relative;
  }

  & :global(.rdw-embedded-modal-btn-section),
  & :global(.rdw-image-modal-btn-section) {
    justify-content: space-between;
    display: flex;
    width: 100%;
  }

  & :global(.rdw-image-alignment-option) {
    font-size: 10px;
  }

  & :global(.rdw-image-modal-btn),
  & :global(.rdw-link-modal-btn) {
    min-height: 20px;
    padding: 0;
    width: calc(50% - 10px);
  }

  & :global(.rdw-link-modal) {
    height: auto;

    & label {
      font-size: 14px;
    }

    & input[type="checkbox"] {
      display: inline;
      width: auto;
    }

    & input {
      width: auto;
      margin-bottom: 12px;
    }

    & :global(.rdw-link-modal-buttonsection) {
      display: flex;
      justify-content: space-between;
      width: 100%;

      & button {
        margin: 0;
        font-weight: var(--fontWeightSemiBold);
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        font-style: normal;
        background-color: var(--colorWhite);
        border: 1px solid var(--marketplaceColor);
        color: var(--marketplaceColor);
        box-shadow: none;

        &:hover,
        &:focus {
          background-color: var(--colorWhite);
          border: 1px solid var(--marketplaceColorDark);
          color: var(--marketplaceColorDark);
        }

        &:disabled {
          box-shadow: none;
          cursor: not-allowed;
          background-color: var(--colorGrey500);
          color: var(--colorWhite);
          border: 1px solid var(--colorGrey500);
        }
      }
    }
  }


  & :global(.rdw-link-decorator-wrapper) a span {
    color: var(--marketplaceColorDark)!important;
    text-decoration: underline;
    background-color: transparent!important;

    &:hover,
    &:focus {
      color: var(--marketplaceColor)!important;
    }
  }

  & :global(.rdw-colorpicker-modal-options) {
    overflow: hidden;
  }

  & :global(.rdw-colorpicker-option) {
    width: 24px;
    height: 24px;
    min-width: 24px;
    margin: 0 2px;
  }

  & :global(.rdw-history-wrapper) {
    order: 2;
  }

  & :global(.rdw-link-modal) {
    left: -112px!important;
  }

  & :global(.rdw-link-modal-target-option) {
    display: none;
  }

  & :global(.rdw-emoji-modal) {
    left: auto;
    right: -20px;

    @media (--viewportMedium) {
      left: 5px;
      right: auto;
    }
  }
}

.editor {
  padding: 0 16px 11px;
  margin-bottom: 30px;

  font-size: 16px;
  
  @media (--viewportMedium) {
    padding: 0 20px 11px;
    font-size: 14px;
  }
}


@import '../../styles/customMediaQueries.css';

.root {
  /* width: 180px;
  min-height: 36px;
  padding: 9px 0; */

  font-size: 14px;
  line-height: 17px;
  font-weight: var(--fontWeightMedium);
  cursor: pointer;
  max-width: 180px;

  @media (--viewportLarge) {
    max-width: 100%;
  }

  @media (--viewportSmall) {
    max-width: 320px;
  }

  @media screen and (max-width: 768px) {
    margin-left: 20px;
  }
}

.simpleIcon {
  max-width: 54px;
  background: none;
  border: 1px solid var(--marketplaceColor);
  border-radius: var(--borderRadiusMedium);
  padding: 16px 0;
  flex-shrink: 0;

  &:hover {
    background: none;
    box-shadow: none;
  }
}

.icon {
  width: 23px;
  height: 20px;

  & path {
    stroke: var(--marketplaceColor);
  }
}

.iconActive {
  fill: var(--marketplaceColor);
}

.listPopupRoot {

  @media (--viewportMedium) {
    flex-basis: 480px !important;
  }

  & > button {
    display: none;
  }
}

.listExistingPopupRoot {

  @media (--viewportMedium) {
    flex-basis: 760px !important;
  }

  & > button {
    display: none;
  }
}

.listPopupTitle {
  padding: 0;
  margin: 0 0 20px;
}

.listPopupForm {

}

.listPopupField {

}

.listPopupCharacters {
  font-size: 11px;
  font-weight: var(--fontWeightMedium);
  line-height: 16px;
  color: var(--colorGrey500);
}

.listPopupButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 37px;

  & .listPopupButtonCancel,
  & .listPopupButtonSave {
    width: calc(50% - 5px);
  }
}

.listPopupButtonCancel {
  border: 1px solid var(--marketplaceColor);
  color: var(--marketplaceColor);
  background-color: var(--colorWhite);
}

.listPopupButtonSave {

}

.listPopupButtonCreateNew {
  composes: buttonDefault from global;
}

.listPopupCloseIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

@import '../../styles/customMediaQueries.css';

.root {
  fill: none;
  /* padding: 5px; */
  /* background: var(--colorWhite);   */

  & svg {
    width: 32px;
    height: 32px;
  }

  & path {
    fill: none;
    stroke: var(--colorWhite);
  }
}

.rootOverlay {
  & path {
    fill: rgba(0, 0, 0, 0.5);
    stroke: var(--colorWhite);
  }
}

.rootActive {

  & path {
    fill: var(--marketplaceColor);
    stroke: var(--colorWhite);
  }
}

.rootText {
  composes: button buttonFont buttonText buttonBorders buttonColors from global;
  min-width: 161px;
  line-height: 43px;
  padding: 0 12px;
  
  text-align: center;
}

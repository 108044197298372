@import '../../styles/customMediaQueries.css';

.listPopupContent {
  
}

.listPopupTitle {
  padding: 0;
  margin: 0 0 20px;
}

.listPopupButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 37px;

  & .listPopupButtonCancel,
  & .listPopupButtonDelete {
    width: calc(50% - 5px);
  }
}

.listPopupButtonCancel {
  border: 1px solid var(--marketplaceColor);
  background-color: var(--colorWhite);
  color: var(--marketplaceColor);
}

.listPopupButtonDelete {
  border: 1px solid var(--colorFail);
  background-color: var(--colorFail);
  color: var(--colorWhite);

  &:hover,
  &:focus {
    border: 1px solid var(--colorFailDark);
    background-color: var(--colorFailDark);
    color: var(--colorWhite);
  }
}

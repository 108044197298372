@import '../../../styles/customMediaQueries.css';

.root {
  position: relative;
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--colorGrey100);

  @media (--viewportMedium) {
    padding-top: 16px;
    padding-bottom: 0;
    border-bottom: 0;
  }
}

.filterHeader {
  line-height: 24px;
}

.labelButton {
  /* Override button styles */
  width: 100%;
  outline: none;
  line-height: 24px;
  text-align: left;
  border: none;
  padding: 0;
  cursor: pointer;
}

.labelButtonContent {
  display: inline-block;
  width: 100%;

  &:hover {
    color: var(--marketplaceColor);

    & * {
      color: var(--marketplaceColor);
      stroke: var(--marketplaceColor);
    }
  }
}

.labelWrapper {
  display: inline-block;
  max-width: calc(100% - 16px);
}

.label,
.labelSelected {
  composes: marketplaceSmallFontStyles from global;
  font-weight: var(--fontWeightBold);

  /* Baseline adjustment for label text */
  margin-top: 0;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    margin-bottom: 8px;
  }
}

.label {
  color: var(--colorGrey700);
}

.labelSelected {
  color: var(--marketplaceColor);
  margin-left: 5px;
}

.openSign {
  float: right;
}

.clearButton {
  composes: h5 from global;
  margin: 0;
  display: block;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey300);
  padding: 2px 0 4px 12px;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }

  @media (--viewportMedium) {
    padding: 4px 0 4px 12px;
    margin-top: 8px;
  }
}

.plain {
  width: 100%;
  display: none;

  @media (--viewportMedium) {
    padding-bottom: 16px;
  }
}

.isOpen {
  display: block;
}

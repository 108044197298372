@import '../../styles/customMediaQueries.css';

.root {
  background-color: var(--colorWhite);
}

.header {
  display: flex;
  justify-content: flex-start;
  padding: 18px 20px;

  & > a {
    padding: 0;
  }

  & > a img {
    height: 24px;
  }
}

.main {

  & :global(#home-page-popup-banner) {

    & [class*="CustomAppearance_backgroundImageWrapper"] {
      &::before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: linear-gradient(90deg, rgba(43, 43, 43, 0.90) 0.08%, rgba(27, 27, 27, 0.00) 99.91%, rgba(217, 217, 217, 0.00) 99.92%);
      }
    }

    & [class*="SectionContainer_sectionContent"] {

      @media (--viewportMedium) {
        padding: 38px 0;
      }
    }
    
    & header {
      max-width: 520px;
      
      @media (--viewportMedium) {
        margin-left: 36px;
      }
      
      & h1,
      & h2,
      & p {
        width: 100%;
        max-width: 100%;
        text-align: center;
        
        @media (--viewportMedium) {
          text-align: left;
        }
      }

      & h1,
      & h2 {
        padding: 0;
        margin: 0 0 10px;
        font-size: 26px;
        line-height: 34px;
        font-weight: 600;
      }

      & p {
        padding: 0;
        margin: 0 0 24px;
        font-size: 16px;
      }

      & [class*="Link_linkWrapper"],
      & a {
        justify-self: center;
        
        @media (--viewportMedium) {
          justify-self: flex-start;
        }
      }
    }
  }

  & :global(#home-page-popup-main) {
    text-align: center;

    & [class*="SectionContainer_sectionContent"] {
      
      @media (--viewportMedium) {
        padding: 60px 0 70px;
      }
    }

    & h1,
    & h2,
    & h3 {
      padding: 0;
      margin: 0 0 15px;
      font-size: 26px;
      line-height: 34px;
      font-weight: 600;
    }

    & p {
      padding: 0;
      margin: 0 0 30px;
      font-size: 16px;
    }

    & [class*="BlockContainer_root"] {
      position: relative;

      &:first-child {

        & :after {
          content: "";
          display: none;
          width: 1px;
          height: 170px;
      
          position: absolute;
          top: 0;
          right: -20px;
          background-color: #DDDDDD;
      
          @media (--viewportMedium) {
            display: inline-block;
          }
        }
      }
    }

    & a, 
    & [class*="Link_link"] {
      margin: 0 auto;
    }
  }


  & :global(#home-page-popup-footer) {

    & [class*="SectionBuilder_sectionDetails"] {
      justify-content: center;
    }

    & [class*="SectionBuilder_align"] {
      justify-self: center;
      text-align: center;
    }

    & [class*="SectionContainer_sectionContent"] {
      padding: 42px 0 100px;
        
      @media (--viewportMedium) {
        padding: 42px 0 58px;
      }
    }

    & h1,
    & h2,
    & h3 {
      padding: 0;
      margin: 0 0 15px;
      font-size: 26px;
      line-height: 34px;
      font-weight: 600;
    }

    & p {
      padding: 0;
      margin: 0 0 22px;
      font-size: 16px;
    }
  }

  & section a, 
  & section [class*="Link_link"] {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 170px;
    min-height: 50px;
    margin: 0;
    /* Padding is only for <a> elements where button styles are applied,
    buttons elements should have zero padding */
    padding: 16px 0 16px 0;
    transition: all var(--transitionStyleButton);
    cursor: pointer;
    /*
     * Font: most font styles are defined earlier in this file
     */
    font-weight: var(--fontWeightMedium);
    /* Button text styles */
    /* Default button text is centered without decorations */
    text-align: center;
    text-decoration: none;
    /* Button borders */
    /* Default button has no borders */
    border: none;
    border-radius: var(--borderRadiusMedium);
    box-shadow: none;
    /* Button colors */
    /* Default button uses marketplace color */
    background-color: var(--marketplaceColor);
    color: var(--colorWhite);


    font-family: var(--fontFamily);
    font-weight: var(--fontWeightRegular);
    /* No margins for default font */
    font-size: 14px;
    line-height: 24px;
    padding: 0;
  
    @media (--viewportMedium) {
      font-size: 16px;
      line-height: 24px;
      padding: 5px 0 3px 0;
    }

    &:hover,
    &:focus {
      text-decoration: none;
      outline: none;
      background-color: var(--marketplaceColor);
    }

    &:disabled {
      box-shadow: none;
      cursor: not-allowed;
      text-decoration: none;
      background-color: var(--colorGrey300);
      color: var(--colorWhite);
    }
  }
}

.input[type=checkbox]{
	height: 0;
	width: 0;
	visibility: hidden;
}

.label {
	cursor: pointer;
	text-indent: -9999px;
	width: 38px;
	height: 20px;
	background: transparent;
	display: block;
	border-radius: 100px;
	position: relative;
	border: 1px solid #DDDDDD;
}

.label:after {
	content: '';
	position: absolute;
	top: 2px;
	left: 2px;
	width: 14px;
	height: 14px;
	background: #B0B0B0;
	border-radius: 90px;
	transition: 0.3s;
	padding: 0;
}

.input:checked + .label {
	/* background: #bada55; */
  background: var(--marketplaceColor);
}

.input:checked + .label:after {
	left: calc(100% - 5px);
	transform: translateX(-73%);
	background: #fff;
}

.label:active:after {
	/* width: 130px; */
}

.checkboxContainer {
  margin-left: 10px;
}

@import '../../styles/customMediaQueries.css';

.root {
  margin: 25px 0 35px;
}

.policyTitle {
    margin: 0 0 8px;
}

.policyItem {
  padding: 0;
  margin: 0 0 10px;

  & b {
    font-weight: var(--fontWeightSemiBold);
  }
}

@import '../../styles/customMediaQueries.css';

.root {
  position: relative;
  width: 100%;
  margin: 0 0 20px 0;
  
  @media (--viewportMedium) {
    margin: 0 20px 0 0;
  }
}

.rooLink {
  color: var(--colorGrey700);

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.navLeft,
.navRight {
  position: absolute;
  width: 36px;
  height: 36px;
  border: 0;
  padding: 0;
  z-index: 1;

  top: calc(50% - 12px);
  opacity: 0;

  /* center content */
  display: flex;
  align-items: center;
  justify-content: center;

  transition: opacity 0.3s;

  &:hover {
    cursor: pointer;

    & .navArrowWrapper {
      background-color: rgba(255, 255, 255, 1);
    }
  }
}

.navLeft {
  left: 10px;

  & .navArrowWrapper {
    padding-right: 2px;
  }
}

.navRight {
  right: 10px;

  & .navArrowWrapper {
    padding-left: 2px;
  }
}

.navArrowWrapper {
  display: none;

  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid rgba(222, 222, 222, 0.75);
  background: rgba(255, 255, 255, 0.5);

  @media (--viewportLarge) {
    /* center content */
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.itemWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    padding-bottom: 300px !important;
  }
}

.itemCentering {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  /* background-color: var(--colorGrey100); */
}

.item {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.noImage {
  border: 1px solid var(--colorGrey100);
  border-radius: var(--borderRadiusMedium);
}

.orderPanelTitle {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2;
  margin: 0 0 5px;
}

.productGallery {
  margin: 0 0 10px 0;
  overflow: hidden;
  
  & :global(.image-gallery-swipe),
  & :global(.image-gallery-slide-wrapper) {
    position: relative;
    max-height: 300px;
    border: none;
  }

  & :global(.image-gallery-bullets) {
    bottom: 20px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    width: 80%;
    z-index: 4;
  }

  & :global(.image-gallery-bullets .image-gallery-bullets-container) {
    margin: 0;
    padding: 0;
    text-align: center;
  }

  & :global(.image-gallery-bullets .image-gallery-bullet) {
    appearance: none;
    background-color: transparent;
    border: 1px solid var(--colorGrey300);
    border-radius: 50%;
    box-shadow: 0 1px 0 #1a1a1a;
    cursor: pointer;
    display: inline-block;
    margin: 0 3px;
    outline: none;
    padding: 3px;
    transition: background .2s ease-out; 
  }

  & :global(.image-gallery-bullets .image-gallery-bullet.active) {
    background: var(--colorWhite);
  }

  &:hover .navLeft,
  &:hover .navRight {
    opacity: 1;
  }
}

.productGallerySingle {
  overflow: hidden;

  & :global(.image-gallery-bullets .image-gallery-bullet) {
    display: none;
  }
}

.listingLocation {
  font-size: 12px;
  line-height: 1.5;
  margin: 0 0 5px;

  & svg {
    width: 14px;
    height: 14px;
    flex-shrink: 0;
    margin-right: 2px;
  }
}

.location {
  font-weight: 400;
  margin-right: 5px;
}

.pricesContainer {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
}

.priceItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  margin: 0;
}

.priceItemDaily {
  order: -1;
}

.priceTime {
  font-size: 12px;
  font-weight: 600;
  line-height: 1.5;
}

.priceAmount {
  font-size: 11px;
  font-weight: 400;
  line-height: 1.5;
}

.totalDivider {
  /* dimensions */
  width: 100%;
  height: 1px;
  margin: 10px 0;

  border: none;
  background-color: var(--colorGrey300);
}

.reviewsContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  font-size: 12px;
  line-height: 1.5;
}

.reviewUser {
  font-weight: 400;
}

.reviewCount {
  font-weight: 400;
}

.additionalRentals {
  font-weight: 600;
  margin-right: 10px;
  font-size: 12px;
  line-height: 1.5;
}

.additionalRentalItem {
  margin: -2px 10px 0 0;

  & svg {
    fill: none;
  }
}

.reviewHolder {
  display: flex;
  align-items: center;

  & svg {
    width: 16px;
    height: 16px;
    margin: -4px 7px 0 0;
  }
}

.reviewRate {
  font-weight: 600;
  margin-right: 2px;
}

.link {
  white-space: nowrap;
  font-weight: 400;
  color: var(--marketplaceColor);
}

.additionalRentalsContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.sectionHeading,
.sectionHeadingWithExtraMargin {
  /* Font for sections titles */
  composes: p from global;
  padding: 0;
  font-weight: var(--fontWeightBold);

  margin-top: 0;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 8px;
  }
}

.sectionHeadingWithExtraMargin {
  @media (--viewportMedium) {
    margin-bottom: 16px;
  }
}

.favoritesContainer {
  position: absolute;
  z-index: 1;
  right: 10px;
  top: 10px;
  cursor: pointer;

  & svg {
    width: 32px;
    height: 32px;
  }
}

.iconLink {
  margin: 0 4px;

  & svg {
    fill: none;

    &:hover,
    &:focus {
      filter: brightness(.1);
    }
  }
}

@import '../../styles/customMediaQueries.css';

.root {
}

.currencyInputWrapper {
  position: relative;
}

.rightIcon {
  position: absolute;
  top: 6px;
  right: 12px;
  
  @media (--viewportMedium) {
    top: 12px;
  }
}

@import '../../styles/customMediaQueries.css';

.root {
  composes: h4 from global;
  margin-top: 4px;
  margin-bottom: 0;
  color: var(--colorFail);
  overflow: hidden;
  font-size: 13px;
  font-weight: var(--fontWeightRegular);
  line-height: 1.4;
}

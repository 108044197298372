@import '../../styles/customMediaQueries.css';

.inputRoot {
  /* Override react-dates default styles to match input styles */

  & :global(.SingleDatePicker) {
    display: block;
  }

  & :global(.SingleDatePicker_picker__directionLeft) {
    /* !important is added to top because react-dates uses inline style for height */
    /* Similar problem as in issue: https://github.com/airbnb/react-dates/issues/947 */
    box-shadow: var(--boxShadowButton);
    left: unset !important;
    width: calc(100% - 48px);
    background-color: var(--marketplaceColor);
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;

    @media (--viewportMedium) {
      /* !important is added to top because react-dates uses inline style for height */
      /* Similar problem as in issue: https://github.com/airbnb/react-dates/issues/947 */
      top: 48px !important;
      width: 100%;
      min-height: auto;
      box-shadow: var(--boxShadowPopup);
      border-radius: 0 0 6px 6px;
    }
  }

  & :global(.DateInput_input) {
    font-family: var(--fontFamily);
    font-weight: var(--fontWeightMedium);
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.1px;

    display: block;
    width: 100%;

    padding: 5px 30px 5px 8px;
    margin: 0;
    border-radius: 6px;
    border: 1px solid #d8dce6;
    background-color: #fff;
    outline: none;

    transition: all 0.1s ease-in-out;

    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.625 4.3125H16.6875V2.8125C16.6875 2.70937 16.6031 2.625 16.5 2.625H15.1875C15.0844 2.625 15 2.70937 15 2.8125V4.3125H9V2.8125C9 2.70937 8.91563 2.625 8.8125 2.625H7.5C7.39687 2.625 7.3125 2.70937 7.3125 2.8125V4.3125H3.375C2.96016 4.3125 2.625 4.64766 2.625 5.0625V20.625C2.625 21.0398 2.96016 21.375 3.375 21.375H20.625C21.0398 21.375 21.375 21.0398 21.375 20.625V5.0625C21.375 4.64766 21.0398 4.3125 20.625 4.3125ZM19.6875 19.6875H4.3125V10.7812H19.6875V19.6875ZM4.3125 9.1875V6H7.3125V7.125C7.3125 7.22813 7.39687 7.3125 7.5 7.3125H8.8125C8.91563 7.3125 9 7.22813 9 7.125V6H15V7.125C15 7.22813 15.0844 7.3125 15.1875 7.3125H16.5C16.6031 7.3125 16.6875 7.22813 16.6875 7.125V6H19.6875V9.1875H4.3125Z' fill='%23222222'/%3E%3Crect x='7' y='12' width='2' height='2' fill='%23222222'/%3E%3Crect x='7' y='16' width='2' height='2' fill='%23222222'/%3E%3Crect x='11' y='12' width='2' height='2' fill='%23222222'/%3E%3Crect x='11' y='16' width='2' height='2' fill='%23222222'/%3E%3Crect x='15' y='12' width='2' height='2' fill='%23222222'/%3E%3Crect x='15' y='16' width='2' height='2' fill='%23222222'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: calc(100% - 6px) 4px;

    cursor: pointer;

    &:hover {
      border: 1px solid var(--colorGrey500);
      transition: all ease-in-out 100ms;
    }
    &:focus {
      border-radius: 6px 6px 0 0;
      border-color: var(--colorGrey500);
      transition: all ease-in-out 100ms;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.625 4.3125H16.6875V2.8125C16.6875 2.70937 16.6031 2.625 16.5 2.625H15.1875C15.0844 2.625 15 2.70937 15 2.8125V4.3125H9V2.8125C9 2.70937 8.91563 2.625 8.8125 2.625H7.5C7.39687 2.625 7.3125 2.70937 7.3125 2.8125V4.3125H3.375C2.96016 4.3125 2.625 4.64766 2.625 5.0625V20.625C2.625 21.0398 2.96016 21.375 3.375 21.375H20.625C21.0398 21.375 21.375 21.0398 21.375 20.625V5.0625C21.375 4.64766 21.0398 4.3125 20.625 4.3125ZM19.6875 19.6875H4.3125V10.7812H19.6875V19.6875ZM4.3125 9.1875V6H7.3125V7.125C7.3125 7.22813 7.39687 7.3125 7.5 7.3125H8.8125C8.91563 7.3125 9 7.22813 9 7.125V6H15V7.125C15 7.22813 15.0844 7.3125 15.1875 7.3125H16.5C16.6031 7.3125 16.6875 7.22813 16.6875 7.125V6H19.6875V9.1875H4.3125Z' fill='%23222222'/%3E%3Crect x='7' y='12' width='2' height='2' fill='%23222222'/%3E%3Crect x='7' y='16' width='2' height='2' fill='%23222222'/%3E%3Crect x='11' y='12' width='2' height='2' fill='%23222222'/%3E%3Crect x='11' y='16' width='2' height='2' fill='%23222222'/%3E%3Crect x='15' y='12' width='2' height='2' fill='%23222222'/%3E%3Crect x='15' y='16' width='2' height='2' fill='%23222222'/%3E%3C/svg%3E%0A");
    }

    &::placeholder {
      color: var(--colorGrey300);
    }

    @media (--viewportMedium) {
      background-position: 90% 10px;
      font-size: 16px;
      line-height: 32px;
      padding: 7px 32px 7px 16px;

      &:focus {
        border-radius: 6px 6px 0 0;
        border-color: var(--colorGrey500);
        transition: all ease-in-out 100ms;
      }
    }
  }

  & :global(.SingleDatePickerInput) {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: none;
    background: none;
  }
  & :global(.DayPicker__horizontal) {
    margin: 0 auto;
    background-color: var(--marketplaceColor);
    box-shadow: none;
  }

  & :global(.DayPickerNavigation__horizontal) {
    position: relative;
    width: 100%;
  }

  & :global(.DayPickerNavigation_button__horizontal) {
    padding: 6px 9px;
    top: 16px;
    position: absolute;
    cursor: pointer;
    display: inline;

    &:first-of-type {
      left: 24px;
    }

    &:last-of-type {
      right: 24px;
    }
  }

  & :global(.DayPickerNavigation_button) {
    color: var(--colorWhite);
    border: 0;
  }

  & :global(.CalendarMonth),
  & :global(.CalendarMonthGrid) {
    background-color: transparent;
  }
  & :global(.DateInput) {
    display: block;
    width: 100%;
  }
  & :global(.DayPicker_weekHeader) {
    color: var(--colorWhite);
    top: 57px;
  }

  & :global(.DayPicker_weekHeader_li) {
    font-weight: var(--fontWeightRegular);
  }

  & :global(.CalendarMonth_caption) {
    font-weight: var(--fontWeightRegular);
    font-size: 21px;
    line-height: 24px;
    color: var(--colorWhite);

    margin: 1px 0 14px;

    @media (--viewportMedium) {
      line-height: 32px;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  & :global(.CalendarDay__default) {
    background-color: var(--marketplaceColor);
    border: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }
  & :global(.CalendarDay) {
    font-weight: var(--fontWeightMedium);
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0;

    color: var(--colorWhite);
    border: 0;
    margin-top: 0;
    margin-bottom: 0;

    @media (--viewportMedium) {
      font-weight: var(--fontWeightMedium);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  /* Add an underline for '.renderedDay' */
  & :global(.CalendarDay__today .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateInput_selectionHeight);
    background-image: url("data:image/svg+xml;utf8,<svg width='14' height='2' viewBox='0 0 14 2' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h14v2H0z' fill='%23FFF' fill-rule='evenodd'/></svg>");
    background-position: center 28px;
  }

  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__hovered_span),
  & :global(.CalendarDay__selected_span) {
    background-image: transparent;
    background-color: transparent;
  }
  & :global(.CalendarDay__hovered_span .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateInput_selectionHeight);
    background-color: var(--DateInput_hoveredOverlayColor);
  }
  & :global(.CalendarDay__selected_span .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateInput_selectionHeight);
    background-color: var(--marketplaceColorDark);
    transition: all 0.2s ease-out;
  }
  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__selected) {
    background-color: transparent;
    background-image: none;
  }
  & :global(.CalendarDay__selected .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateInput_selectionHeight);
    background-color: var(--DateRangeInput_hoveredOverlayColor);
    color: #fff;
    border: 1px solid #fff;
    border-radius: 3px;
  }
  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__after-hovered) {
    background-color: transparent;
  }
  & :global(.CalendarDay__after-hovered .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateInput_selectionHeight);
    background-color: var(--DateRangeInput_hoveredOverlayColor);
    color: #fff;
    border: 1px solid #fff;
    border-radius: 3px;
  }
  & :global(.CalendarDay:hover .renderedDay) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateInput_selectionHeight);
    background-color: var(--DateRangeInput_hoveredOverlayColor);
    color: #fff;
    border: 1px solid #fff;
    border-radius: 3px;
  }
  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__blocked_out_of_range),
  & :global(.CalendarDay__blocked_out_of_range:active),
  & :global(.CalendarDay__blocked_out_of_range:hover) {
    background-color: transparent;
    color: var(--marketplaceColorDark);
    border: 0;
  }
  /* Remove default bg-color and use our extra span instead '.renderedDay' */
  & :global(.CalendarDay__blocked_calendar),
  & :global(.CalendarDay__blocked_calendar:active),
  & :global(.CalendarDay__blocked_calendar:hover) {
    background-color: transparent;
    color: var(--marketplaceColorDark);
    border: 0;
  }
  & :global(.CalendarDay__blocked_out_of_range .CalendarDay__blocked_calendar .renderedDay) {
    background-color: transparent;
  }
  & :global(.DateInput_fang) {
    display: none;
  }
  & :global(.CalendarMonth_caption) {
    text-transform: capitalize;
  }

  & :global(.DateInput__disabled) {
    background: none;
  }
  & :global(.DateInput_input__disabled) {
    font-family: var(--fontFamily);
    font-weight: var(--fontWeightMedium);
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.1px;

    /* Dimensions */
    display: block;
    width: 100%;
    margin: 0;

    /* 6 + 24 + 4 + borders are divisible by 6 */
    padding: 6px 12px 4px 30px;
    background-color: #fff;

    /* Borders */
    border-radius: 6px;
    border: 1px solid #d8dce6;
    border-bottom-style: solid;
    border-bottom-color: var(--colorGrey100);
    outline: none;

    /* Transition */
    transition: border-bottom-color var(--transitionStyle);
    transition: all 0.15s ease-out;

    background: none;
    background-image: url('data:image/svg+xml;utf8,<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><g stroke="%23E6E6E6" transform="translate(1 1)"><rect stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" x=".292" y="1.459" width="13.417" height="12.25" rx="1"/><path d="M3.208.292V3.21M10.792.292V3.21M.292 4.376h13.416" stroke-linecap="round" stroke-linejoin="round"/><rect x="3.5" y="6.5" width="1" height="1" rx=".5"/><rect x="6.5" y="6.5" width="1" height="1" rx=".5"/><rect x="9.5" y="6.5" width="1" height="1" rx=".5"/><rect x="3.5" y="9.5" width="1" height="1" rx=".5"/><rect x="6.5" y="9.5" width="1" height="1" rx=".5"/><rect x="9.5" y="9.5" width="1" height="1" rx=".5"/></g></g></svg>');
    background-repeat: no-repeat;
    background-position: 8px 8px;

    /* Effects */
    &:hover {
      border: 1px solid var(--colorGrey500);
      transition: all ease-in-out 100ms;
    }
    &:focus {
      background-color: #fff;
      border-color: var(--colorGrey500);
      transition: all ease-in-out 100ms;
    }
    &:disabled {
      opacity: 0.5;

      &:hover {
        cursor: not-allowed;
      }
    }

    @media (--viewportMedium) {
      font-size: 16px;
      line-height: 32px;
      /* 7 + 32 + 7 + borders = 48, which is divisible by 8. */
      padding: 7px 16px 7px 32px;
      line-height: 32px;
      background-position: 8px 13px;
    }
  }

  & :global(.DateInput_input__disabled::placeholder) {
    color: var(--colorGrey100);
    font-style: normal;
  }
}

/**
 * Mobile margins mean that labels, inputs (incl separate borders) get margins,
 * but calendar popup doesn't.
 */
.withMobileMargins {
  & :global(.SingleDatePickerInput) {
    width: 100%;
    margin: 0;

    @media (--viewportMedium) {
      width: 100%;
      margin: 0;
    }
  }

  & :global(.SingleDatePicker_picker__directionLeft) {
    /* !important is added to top because react-dates uses inline style for height */
    /* Similar problem as in issue: https://github.com/airbnb/react-dates/issues/947 */
    top: 34px !important;

    @media (--viewportMedium) {
      /* !important is added to top because react-dates uses inline style for height */
      /* Similar problem as in issue: https://github.com/airbnb/react-dates/issues/947 */
      top: 40px !important;
      border-top: 0;
      min-height: auto;
    }
  }

  /* Create gutter between inputs */
  & :global(.DateInput) {
    width: 100%;
    background: none;
  }
}

.rootNextMonthIcon,
.rootPreviousMonthIcon {
  stroke: var(--colorWhite);
  fill: var(--colorWhite);
}

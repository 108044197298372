@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
}

.nowrap {
  white-space: nowrap;
}

.lineItem {
  composes: h4 from global;
  margin: 0;
  padding-bottom: 0;

  @media (--viewportMedium) {
    padding-top: 12px;
    padding-bottom: 1px;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bookingPeriod {
  flex: 1 1;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.bookingPeriodSectionLeft {
  text-align: left;
}

.bookingPeriodSectionRight {
  text-align: right;
}

.dayLabel {
  padding: 0;
  margin: 0;

  font-size: 14px;
  font-weight: var(--fontWeightSemiBold);
  line-height: 24px;
  color: var(--colorGrey500);
}

.dayInfo,
.totalPrice {
  composes: p from global;
  font-weight: var(--fontWeightSemiBold);
  
  margin: 0;
  padding: 0;
}

.dayInfo {
  font-weight: var(--fontWeightSemiBold);
  letter-spacing: 0;
}

.subTotalLineItem {
  composes: h4 from global;
  font-weight: var(--fontWeightBold);
  margin: 0;
  padding: 5px 0 1px 0;

  @media (--viewportMedium) {
    padding: 7px 0 1px 0;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.lineItemTotal {
  composes: h4 from global;
  margin: 0;
  padding-top: 6px;
  padding-bottom: 3px;

  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;

  @media (--viewportMedium) {
    padding-top: 12px;
  }
}

.itemLabel {
  flex: 1;
  line-height: 24px;
  font-weight: var(--fontWeightRegular);
}

.itemLabelCustom {
  max-width: 75%;
  word-break: break-word;
}

.itemValue {
  line-height: 24px;
  font-weight: var(--fontWeightRegular);
  margin: 0 0 0 10px;
}

.totalDivider {
  /* dimensions */
  width: 100%;
  height: 1px;
  margin: 12px 0 12px 0;

  border: none;
  background-color: var(--colorGrey100);

  @media (--viewportLarge) {
    margin: 12px 0 0px 0;
  }
}

.totalLabel {
  line-height: 24px;
  font-weight: var(--fontWeightRegular);
}

.feeInfo {
  composes: marketplaceTinyFontStyles from global;
  flex-shrink: 0;
  margin: 0;
  color: var(--colorGrey300);
  padding-top: 4px;
  padding-bottom: 14px;

  @media (--viewportMedium) {
    padding-top: 11px;
    padding-bottom: 21px;
  }
}

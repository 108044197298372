@import '../../styles/customMediaQueries.css';

.cropContainer {
  text-align: center;
  margin-bottom: 20px;
}

.buttons {
  display: flex;
}

.buttons > button {
  @apply --marketplaceModalBtn;
  margin-right: 12px;
}

.buttons > button:last-child {
  margin-right: 0;
  margin-left: 12px;
}
